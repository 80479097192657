
.canvas-container {
  opacity: 1;
  height: 100%;
  display: flex;
  align-items: center;
}
.invisible {
  opacity: 0;
}
.car-canvas {
  position: relative;
  flex: 1;
}

.pipette {
  position: absolute;
  left: 50px;
  top: -30px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 8px 0px;
  z-index: 95;
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
}
