
$spinkit-size: 4em !default;
$spinkit-spinner-color: #337ab7 !default;

.sk-double-bounce {
  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;
  margin: auto;

  .sk-child {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $spinkit-spinner-color;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-double-bounce 2s infinite ease-in-out;
  }

  .sk-double-bounce-2 {
    animation-delay: -1s;
  }
}

@keyframes sk-double-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
